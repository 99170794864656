var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"all-news-page"},[_c('div',{staticClass:"section-first-news"},[(_vm.getFirst)?_c('div',{staticClass:"box-first-news"},[_c('a',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":_vm.getFirst.article_thumbnail,"alt":"thumnail"}})]),_c('div',{staticClass:"content-news"},[_c('div',{staticClass:"info-news"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime( _vm.getFirst.created_at, _vm.getFirst.article_lang ))+" ")]),_c('router-link',{attrs:{"to":{
                            name: 'Detail',
                            params: {
                                locale: _vm.$i18n.locale,
                                type: _vm.getFirst.blog_slug,
                                id: _vm.getFirst.article_slug,
                            },
                        }}},[_c('div',{staticClass:"type-news"},[_vm._v(" "+_vm._s(_vm.getFirst.article_blog_name)+" ")])])],1),_c('router-link',{attrs:{"to":{
                        name: 'Detail',
                        params: {
                            locale: _vm.$i18n.locale,
                            type: _vm.getFirst.blog_slug,
                            id: _vm.getFirst.article_slug,
                        },
                    }}},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.getFirst.article_title))])]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.getFirst.article_description)}})],1)]):_c('div',{staticClass:"text-center d-flex h-100 align-items-center justify-content-center",staticStyle:{"min-height":"300px","font-size":"25px","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.$t('article.content'))+" ")])]),_c('div',{staticClass:"section-news"},[_c('b-row',{staticClass:"list-news"},[_vm._l((_vm.getFromSecond),function(news){return _c('b-col',{key:("box-news-" + (news.article_id)),attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"news-box"},[_c('router-link',{staticClass:"thumbnail",attrs:{"to":{
                            name: 'Detail',
                            params: {
                                locale: _vm.$i18n.locale,
                                type: news.blog_slug,
                                id: news.article_slug,
                            },
                        }}},[_c('img',{attrs:{"src":news.article_thumbnail,"alt":""}})]),_c('div',{staticClass:"content-news"},[_c('div',{staticClass:"info-news"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime( news.created_at, news.article_lang ))+" ")]),_c('router-link',{attrs:{"to":{
                                    name: 'Detail',
                                    params: {
                                        locale: _vm.$i18n.locale,
                                        type: news.blog_slug,
                                        id: news.article_slug,
                                    },
                                }}},[_c('div',{staticClass:"type-news"},[_vm._v(" "+_vm._s(news.article_blog_name)+" ")])])],1),_c('router-link',{attrs:{"to":{
                                name: 'Detail',
                                params: {
                                    locale: _vm.$i18n.locale,
                                    type: news.blog_slug,
                                    id: news.article_slug,
                                },
                            }}},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(news.article_title)+" ")])])],1)],1)])}),(
                    _vm.NewsAll.length >= 1 &&
                    _vm.NewsAll.total > 1 &&
                    _vm.newsAll.current <= _vm.NewsAll.total
                )?_c('b-col',{staticClass:"d-flex justify-content-center align-items-center my-3",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"button-load"},[_vm._v("Load More")])],1):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }